import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const apiKey = "f3ie9D8MvzwyVizVqquQif4gEWNwRZqNzARPpnXgtUU4UECS";

const appScopes = [ 
    "read:games",
    "modify:games",
    "create:games",
    "read:sites",
    "modify:sites",
    "read:software",
    "modify:software",
    "create:software"
]

const isValidToken = (loginExpiry) => {
    if (!loginExpiry) {
        return false;
    }

    const currentTime = Date.now() / 1000;
    return loginExpiry > currentTime;
}

const setSession = (email, loginExpiry) => {
    if (email) {
        localStorage.setItem('login_expiry', loginExpiry);
        localStorage.setItem('email', email);
    } else {
        localStorage.removeItem('login_expiry');
        localStorage.removeItem('email');
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    refresh: () => Promise.resolve()
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password, code) => {
        const response = await axios.post('auth/user/token', {
            clientId: email,
            clientSecret: password,
            otpCode: code,
            apiKey: apiKey,
            scopes: appScopes
        })

        if(response.data.HotpRequired) {
            throw new Error("OTP_REQUIRED");
        }

        const { user, ExpiresIn } = response.data;

        const unixNow = Math.round((new Date()).getTime() / 1000);

        setSession(email, ExpiresIn + unixNow);

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const refresh = async (email) => {
        const response = await axios.post('auth/user/refresh', {
            emailAddress: email,
            grantType: 'refresh_token',
            scopes: appScopes
        }).then((response) => {
            return response;
        }).catch((err) => {
            logout();
            return undefined;
        });
        
        if(!response) {
            return;
        }

        const { user, ExpiresIn } = response.data;

        const unixNow = Math.round((new Date()).getTime() / 1000);

        setSession(email, unixNow + ExpiresIn);

        dispatch({
            type: 'INIT',
            payload: {
                isAuthenticated: true,
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const email = window.localStorage.getItem('email')

                if (email) {
                    const loginExpiry = window.localStorage.getItem('login_expiry')

                    if(!isValidToken(loginExpiry)) {
                        await refresh(email);
                    } else {
                        const response = await axios.get('auth/user/profile')
                        const { user } = response.data

                        dispatch({
                            type: 'INIT',
                            payload: {
                                isAuthenticated: true,
                                user,
                            },
                        })
                    }
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                refresh,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
