import NotFound from './NotFound'

const sessionRoutes = [
    {
        path: '/session/404',
        component: NotFound,
    },
]

export default sessionRoutes
