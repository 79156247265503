import React from 'react'

const sitesRoutes = [
    {
        path: '/sites',
        exact: true,
        component: React.lazy(() => import('./ManageSites')),
    },
    {
        path: '/sites/:siteid',
        exact: true,
        component: React.lazy(() => import('./SiteDetail')),
    },
    {
        path: '/sites/:siteid/add-games',
        exact: true,
        component: React.lazy(() => import('./AddGames')),
    }
]

export default sitesRoutes
