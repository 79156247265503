import React from 'react'

const materialRoutes = [
    {
        path: '/:siteId/material/table',
        component: React.lazy(() => import('./tables/AppTable')),
    },
    {
        path: '/:siteId/material/form',
        component: React.lazy(() => import('./forms/AppForm')),
    },
    {
        path: '/:siteId/material/buttons',
        component: React.lazy(() => import('./buttons/AppButton')),
    },
    {
        path: '/:siteId/material/icons',
        component: React.lazy(() => import('./icons/AppIcon')),
    },
    {
        path: '/:siteId/material/progress',
        component: React.lazy(() => import('./AppProgress')),
    },
    {
        path: '/:siteId/material/menu',
        component: React.lazy(() => import('./menu/AppMenu')),
    },
    {
        path: '/:siteId/material/checkbox',
        component: React.lazy(() => import('./checkbox/AppCheckbox')),
    },
    {
        path: '/:siteId/material/switch',
        component: React.lazy(() => import('./switch/AppSwitch')),
    },
    {
        path: '/:siteId/material/radio',
        component: React.lazy(() => import('./radio/AppRadio')),
    },
    {
        path: '/:siteId/material/slider',
        component: React.lazy(() => import('./slider/AppSlider')),
    },
    {
        path: '/:siteId/material/autocomplete',
        component: React.lazy(() => import('./auto-complete/AppAutoComplete')),
    },
    {
        path: '/:siteId/material/expansion-panel',
        component: React.lazy(() =>
            import('./expansion-panel/AppExpansionPanel')
        ),
    },
    {
        path: '/:siteId/material/dialog',
        component: React.lazy(() => import('./dialog/AppDialog')),
    },
    {
        path: '/:siteId/material/snackbar',
        component: React.lazy(() => import('./snackbar/AppSnackbar')),
    },
]

export default materialRoutes
