import React from 'react'

const softwareRoutes = [
    {
        path: '/software',
        exact: true,
        component: React.lazy(() => import('./ManageSoftware')),
    },
    {
        path: '/software/:softwareid',
        exact: true,
        component: React.lazy(() => import('./SoftwareDetail')),
    },
    {
        path: '/software/:softwareid/versions/:softwareversionid',
        exact: true,
        component: React.lazy(() => import('./VersionDetail')),
    },
    {
        path: '/software/:softwareid/versions/:softwareversionid/deploy',
        exact: true,
        component: React.lazy(() => import('./DeployVersion')),
    },
    {
        path: '/software/:softwareid/versions/:softwareversionid/deploy/:versionstatustypeid',
        exact: true,
        component: React.lazy(() => import('./DeployVersion')),
    },
    {
        path: '/software/:softwareid/versions/:softwareversionid/rollback',
        exact: true,
        component: React.lazy(() => import('./RollbackVersion')),
    }
]

export default softwareRoutes
