import React, { useEffect, useRef, useState } from 'react'
import { MatxLoading } from '..'
import axios from 'axios.js'

const authBaseUrl = window.location.hostname === "console.zerolatencyvr.com" ? "https://auth.zerolatencyvr.com" : // Production
    (window.location.hostname === "uat.console.zerolatencyvr.com" ? "https://uat.auth.zerolatencyvr.com" : // UAT
    (window.location.hostname === "dev.console.zerolatencyvr.com" ? "https://dev.auth.zerolatencyvr.com" : // DEV
    "https://dev.auth.zerolatencyvr.com")) // Local
    // "http://localhost:5173")) // Local

export const Logout = () => {
    window.location.href = authBaseUrl + '/logout?redirect=' + encodeURIComponent(window.location.protocol + '//' + window.location.host)
    sessionStorage.clear()
}

export const HasPermission = (permission) => {
    const permissions = JSON.parse(sessionStorage.getItem('permissions'))
    return permissions && (permissions.includes(permission) || permissions.includes('super-admin'))
}

export const GetSites = () => {
    return JSON.parse(sessionStorage.getItem('sites'))
}

export const UserUpdatedEvent = 'user-updated'

const ZLAuth = ({ children }) => {
    const iRef = useRef()
    const contentWindow =
        iRef && iRef.current ? iRef.current.contentWindow : null

    const [authReady, setAuthReady] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)

    const appScopes = [
        'read:games',
        'modify:games',
        'create:games',
        'read:sites',
        'modify:sites',
        'read:software',
        'modify:software',
        'create:software',
    ]

    const scopesString = appScopes.join(',')

    const authPath = authBaseUrl + '?scopes=' + scopesString

    function setSites(sites) {
        sessionStorage.setItem('sites', JSON.stringify(sites))
    }

    function setPermissions(permissions) {
        sessionStorage.setItem('permissions', JSON.stringify(permissions))
    }

    useEffect(() => {
        if (!contentWindow) return
        contentWindow.postMessage({ type: 'ready', scopes: appScopes }, authBaseUrl)
    }, [contentWindow, authReady])

    useEffect(() => {
        if (!contentWindow || !authReady) return

        if(sessionStorage.getItem('user')) {
            return
        }

        axios.get('/auth/user/profile').then(({ data }) => {
            sessionStorage.setItem('user', JSON.stringify(data.user));
            const UserUpdated = new CustomEvent(UserUpdatedEvent, { detail: data.user })
            document.dispatchEvent(UserUpdated)
        })
    }, [contentWindow, authReady])

    useEffect(() => {
        window.addEventListener('message', (e) => {
            if(e.origin !== authBaseUrl) return
            
            switch (e.data.type) {
                case 'login':
                    console.log('Login event received')
                    window.location.href =
                        e.data.url +
                        '?redirect=' +
                        encodeURIComponent(window.location.href)
                    break
                case 'ready':
                    console.log('Auth Ready')
                    setAuthReady(true)
                    if(e.data.loggedIn) setLoggedIn(e.data.loggedIn)
                    if(e.data.sites) setSites(e.data.sites)
                    if(e.data.permissions) setPermissions(e.data.permissions)
                    break
            }
        })
    }, [])

    return (
        <>
            <iframe
                src={authPath}
                ref={iRef}
                title="ZL Auth"
                style={{ position: 'absolute', width: 0, height: 0, border: 0 }}
            />
            {loggedIn ? children : <MatxLoading />}
        </>
    )
}

export default ZLAuth
