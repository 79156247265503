import React from 'react'
import { Redirect } from 'react-router-dom'

import gamesRoutes from './views/games/GamesRoutes'
import softwareRoutes from './views/software/SoftwareRoutes'
import sitesRoutes from './views/sites/SitesRoutes'

import utilitiesRoutes from './views/utilities/UtilitiesRoutes'
import materialRoutes from './views/material-kit/MaterialRoutes'
import pageLayoutRoutes from './views/page-layouts/PageLayoutRoutees'

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/games" />,
    },
]

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />,
    },
]

const routes = [
    ...gamesRoutes,
    ...softwareRoutes,
    ...sitesRoutes,
    ...materialRoutes,
    ...utilitiesRoutes,
    ...pageLayoutRoutes,
    ...redirectRoute,
    ...errorRoute,
]

export default routes
