import React from 'react'

const hardwareRoutes = [
    {
        path: '/games',
        exact: true,
        component: React.lazy(() => import('./ManageGames')),
    },
    {
        path: '/games/new',
        exact: true,
        component: React.lazy(() => import('./AddGame')),
    },
    {
        path: '/games/:gameid',
        exact: true,
        component: React.lazy(() => import('./GameDetail')),
    },
    {
        path: '/games/:gameid/versions/:gameversionid',
        exact: true,
        component: React.lazy(() => import('./VersionDetail')),
    },
    {
        path: '/games/:gameid/versions/:gameversionid/deploy',
        exact: true,
        component: React.lazy(() => import('./DeployVersion')),
    },
    {
        path: '/games/:gameid/versions/:gameversionid/deploy/:versionstatustypeid',
        exact: true,
        component: React.lazy(() => import('./DeployVersion')),
    },
    {
        path: '/games/:gameid/versions/:gameversionid/rollback',
        exact: true,
        component: React.lazy(() => import('./RollbackVersion')),
    }
]

export default hardwareRoutes
